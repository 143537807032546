// import React, { useState, useEffect } from 'react';
import { Spinner, Flex } from '@chakra-ui/react';

const LoadingComponent = () => {
  // const [showContent, setShowContent] = useState(false);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setShowContent(true);
  //   }, 5000);

  //   return () => clearTimeout(timer); // Cleanup the timer on component unmount
  // }, []);

  return (
    <Flex
      height="100vh"
      alignItems="center"
      justifyContent="center"
    >
      {/* {showContent ? ( */}
      {/* Render your content once showContent is true */}
        <div>
          {/* Your content here */}
        </div>
      {/* ) : ( */}
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      {/* )} */}
    </Flex>
  );
};

export default LoadingComponent;
