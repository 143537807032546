import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://api.rajengg.co.in/api/', // Replace with your DRF API URL
  headers: {
    'Content-Type': 'application/json',
    // Add any other headers you might need
  },
});

export default axiosInstance;