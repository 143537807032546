import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';
import Homepage from './components/pages/Homepage';
import './App.css'
import AllProductDetails from './components/pages/productDetails';
import SingleProductDetails from './components/pages/singleProductDetails';
import AboutUs from './components/pages/AboutUs';
import ContactUs from './components/pages/ContactUs';
import { Box } from '@chakra-ui/react';



function App() {
  return (
    <> 
   
      <Router>
         <Header/>
         <Box minH={'100vh'}>
          <Routes>
          

          <Route path="/product/:slug/" element={<SingleProductDetails />} />
          <Route path="/product/" element={<AllProductDetails />} />
          <Route path="/about-us/" element={<AboutUs />} />
          <Route path="contact-us/" element={<ContactUs />} />
          <Route path="/" element={<Homepage />} />
    

        </Routes>
      </Box>
       <Footer/>
      </Router> 
   
     
    </>
  );
}

export default App;