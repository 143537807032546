import React, { useEffect, useRef, useState } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { useParams } from 'react-router-dom';
import { Box, Flex, IconButton, Image, Stack } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  // TableContainer,
} from '@chakra-ui/react'
import LoadingComponent from '../utils/LoadingAnimation';



const SingleProductDetails = () => {
  const [productImage, setProductImage] = useState([]);
  const [productDetail, setProductDetail] = useState([]);
  const { slug } = useParams();
  const carouselRef = useRef(null);
  const [currentImage, setCurrentImage] = useState(0);
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [imageResponse, productResponse] = await Promise.all([
          axiosInstance.get(`product/${slug}/`),
          axiosInstance.get(`single/product/${slug}/`)
        ]);

        const imageData = imageResponse.data;
        const productData = productResponse.data;

        if(imageData && productResponse){
          setIsLoading(false)
        }

        setProductImage(imageData);
        setProductDetail(productData);
      } catch (error) {
        console.log(`Error while fetching product details: ${error.message}`);
        setIsLoading(false)
      }
    };

    fetchData();
 
  }, [slug]);
  const nextImage = () => {
    setCurrentImage((prev) => (prev === productImage.length - 1 ? 0 : prev + 1));
  };

  const prevImage = () => {
    setCurrentImage((prev) => (prev === 0 ? productImage.length - 1 : prev - 1));
  };

  if(isLoading){
    return <LoadingComponent />;
  }

  return (<>
     <Box maxW="800px" mx="auto" position="relative" textAlign="center">
     
     
      <Box position="relative" h={'500px'}
      maxW={{ base: "100%", sm: "50%", md: "75%", lg: "80%" }}
      mx="auto" ref={carouselRef}>
  {productImage.map((img, index) => (
    <Image
      key={index}
      src={img.image}
      alt={`Carousel Image ${index + 1}`}
      boxSize="100%"
      mt={3}
      objectFit="contain"
      position="absolute"
      opacity={index === currentImage ? 1 : 0}
      transition="opacity 0.3s"
      zIndex={index === currentImage ? 1 : 0}
      borderRadius="md"
    />
  ))}
  
</Box>

      <Stack
        direction="row"
        spacing="2"
        position="absolute"
        bottom="4"
        right="4"
      >
        {productImage.map((_, index) => (
          <Box
            key={index}
            boxSize="8px"
            bg={index === currentImage ? "teal.400" : "gray.400"}
            borderRadius="full"
            cursor="pointer"
            onClick={() => setCurrentImage(index)}
            aria-label={`Image ${index + 1}`}
            _focus={{ outline: "none" }}
          />
        ))}
      </Stack>
      <Flex  mb="4">
        <IconButton
        
          icon={<ChevronLeftIcon />}
          aria-label="Previous"
          onClick={prevImage}
          variant="ghost"
          size="lg"
          isRound
        />
        <IconButton
      
          icon={<ChevronRightIcon />}
          aria-label="Next"
          onClick={nextImage}
          variant="ghost"
          size="lg"
          isRound
        />
      </Flex>
    </Box>

    {/* <TableContainer mx={2} m={'5% auto'} maxWidth={'max-content'}> */}
    <Flex direction="column" align="center" justify="center">
      <Table variant='simple' size='md'>
        
        <Thead variant='striped' >
          <Tr>
            <Th>Product Name</Th>
            <Th>Category</Th>
            <Th>Price</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>{productDetail.name}</Td>
            <Td> {productDetail.category?.name} </Td>
            <Td>{  productDetail.price>0? `₹${ productDetail.price}`: `Cotanct Us For Price` }</Td>
          </Tr>
        </Tbody>
        <Tfoot>
          <Tr>
            <Th>{productDetail.availability? (`Currently ${productDetail.stock} unit available`):('Sorry Currently Out of Stock')}</Th>
          </Tr>
        </Tfoot>
      </Table>

      <Table variant='simple' size='md'>
        <Thead>
          
        </Thead>
        <Tbody>
          <Tr><Th>Description</Th>
            <Td colSpan="3" maxW="300px" overflow="auto" whiteSpace="normal">
              {productDetail.description}
            </Td>
          </Tr>
        </Tbody>
        <TableCaption>Raj Engineering.</TableCaption>
      </Table>
    </Flex>
{/* </TableContainer> */}
    </>
  );
}


export default SingleProductDetails;
