import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Flex,
  Spacer,
  Input,
  InputGroup,
  Text,
  InputRightElement,
  IconButton,
  useMediaQuery,
  Image,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  // useColorMode ,
  Link,
  Button,
  // Switch,
  Center,
  Stack,
} from '@chakra-ui/react';
import { SearchIcon, HamburgerIcon } from '@chakra-ui/icons';
import { Link as RouterLink} from 'react-router-dom';
import Instance from './utils/instanceAuthentication';




// function DarkModeToggle() {
//   const { colorMode, toggleColorMode } = useColorMode();

//   return (
//     <Box p={4}>
//       <Switch
      
//         isChecked={colorMode === "dark"}
//         onChange={toggleColorMode}
//         colorScheme="teal"
//         size="lg"
//       />
//     </Box>
//   );
// }







const Header = () => {
  const [isSmallerThanMd] = useMediaQuery("(max-width: 48em)");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState(null);
  const inputRef = useRef();

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSearchResults(null);
    try {
      const response = await Instance(`product/?search=${searchValue}`);
      const data = await response.data;
      setSearchResults(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      event.preventDefault()
      handleSubmit(event);
    }
  }

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  }

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setSearchResults(null);
      }
    };

    document.body.addEventListener('click', handleClickOutside);

    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <Box as="header" p="4" bgColor="blue.300" color="white">
      <Flex alignItems="center">
        <Box mx={0}>
          <Link as={RouterLink} to="/">
            <Center>
              <img
                src='/companylogo.png'
                alt='Company Logo'
                style={{ maxWidth: '100%', height: 'auto', maxHeight: '50px' }}
              />
              
            </Center>
          </Link>
        </Box>

        <Spacer />

        <Center>
          <InputGroup maxW="180px" mt={'10px'}>
            <InputRightElement >
              <IconButton
                bg={'white'}
                color={'black'}         
                fontSize={'x-small'}
                aria-label="Search"
                variant="ghost"
                icon={<SearchIcon color="gray.300" />}
                size="sm"
                onClick={handleSubmit}
              />
            </InputRightElement>
            <Input
              type="text"
              placeholder="Search..."
              borderRadius="md"
              _focus={{ borderColor: '#00A4EF' }}
              py={2}
              pr={4}
              pl={10}
              color="black"
              borderColor="#fff"
              bg={'#fff'}
              value={searchValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyPress}
              ref={inputRef}
            />
          </InputGroup>
         
        </Center>

        <Spacer />

        {isSmallerThanMd ? (
          <>
            <IconButton
              aria-label="Menu"
              variant="ghost"
              icon={<HamburgerIcon color="white" />}
              size="lg"
              onClick={handleDrawerOpen}
            />
            <Drawer placement="right" onClose={handleDrawerClose} isOpen={isDrawerOpen}>
              <DrawerOverlay>
                <DrawerContent>
                  <DrawerCloseButton />
                  <DrawerHeader>Menu</DrawerHeader>
                  <DrawerBody>
                    <Stack spacing={3}>
                    
                      <Button  variant="ghost" as={RouterLink} to="/about-us/" onClick={handleDrawerClose}>About Us</Button>
                      <Button variant="ghost" as={RouterLink} to="/contact-us/" onClick={handleDrawerClose}>Contact</Button>
                      <Button variant="ghost" as={RouterLink} to="/product/" onClick={handleDrawerClose}>Products</Button>
                    </Stack>
                  </DrawerBody>
                </DrawerContent>
              </DrawerOverlay>
            </Drawer>
          </>
        ) : (
          <Stack direction="row" spacing={3}>
             {/* < DarkModeToggle/> */}
            <Button variant="ghost" as={RouterLink} to="/about-us/">About Us</Button>
            <Button variant="ghost" as={RouterLink} to="/contact-us/">Contact</Button>
            <Button variant="ghost" as={RouterLink} to="/product/">Products</Button>
          </Stack>
        )}
      </Flex>
      {searchResults && (
        <Box maxW={'400px'} mt="4" mx={'auto'} p="4" bgColor="#fff" borderRadius="md" boxShadow="md">
          <Text fontWeight="bold"  fontSize="lg">Search Results:</Text>
          {searchResults.map(result => (
            <Box key={result.id} m="2" p="2" borderWidth="1px" borderRadius="md">
              <Box h={'50px'} w={'50px'}>
                <Image boxSize={'100%'} objectFit={'contain'} src={`https://api.rajengg.co.in${result.first_image}`} alt={result.name} />
              </Box>
              <Text color={'black'} fontWeight="bold" fontSize="md">{result.name}</Text>
              <Button size="sm" onClick={() => setSearchResults(null)}  as={RouterLink} to={`/product/${result.slug}/`}>View Product</Button>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default Header;
