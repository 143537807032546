import React, { useEffect, useState } from 'react';
import axiosInstance from '../utils/axiosInstance';
import {
  Box,
  Flex,
  Select,
  Image,
  Text,
  Badge,
  Button,
} from "@chakra-ui/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoadingComponent from '../utils/LoadingAnimation';

const AllProductDetails = () => {
  const [listProductDetails, setListProductDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPage, setNextPage] = useState(true);
  const [previousPage, setPreviousPage] = useState(false);
  const { ordering_param } = useParams();
  const [sortingOption, setSortingOption] = useState(ordering_param || '');
  const router = useNavigate();

  const fetchData = async (page, ordering) => {
    try {
      const productData = await axiosInstance.get(`homepage/?page=${page}&ordering=${ordering}`);
      setListProductDetails(productData.data.results || []); 
      setNextPage(!!productData.data.next);
      setPreviousPage(!!productData.data.previous);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchData(currentPage, sortingOption);
  }, [currentPage, sortingOption]);

  const nextPageButton = async () => {
    if (nextPage) {
      setIsLoading(true)
      setCurrentPage(currentPage + 1);

    }
  }

  const PreviousPageButton = async () => {
    if (previousPage) {
      setIsLoading(true)
      setCurrentPage(currentPage - 1);
    }
  }

  const handleSortingChange = (e) => {
    const selectedOption = e.target.value;

    router(`?ordering=${selectedOption}`);
    setSortingOption(selectedOption);
    setIsLoading(true);
  };

  return (
    isLoading ? <LoadingComponent /> : (
      <Flex direction="column" align="flex-start" maxW="100%">
        <Box mx={2.5} my={2.5} w="100%">
          <Select value={sortingOption} onChange={handleSortingChange} maxW="10%">
            <option value="" disabled>Sort</option>
            <option value="price">Price - Low to High</option>
            <option value="-price">Price - High to Low</option>
            <option value="created_at">Existing</option>
            <option value="-created_at">Latest</option>
          </Select>
        </Box>
        <Flex
          flexWrap="wrap"
          justifyContent="center"
          maxW="100%"
          flexBasis={['100%', '75%', '50%', '33.33%', '25%']}
        >
          {listProductDetails.map(product => (
            <Box
              key={product.id}
               // Adjust the flexBasis values for responsiveness
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              boxShadow="lg"
              m={2.5}
              width="300px" 
              maxW={['100%', '75%', '50%', '33.33%', '25%']} // Adjust the maxW values to maintain the card size
            >
              <Image m={'10px auto'}
               objectFit={'contain'}
               w={'250px'} h={'250px'} 
               borderRadius={'25px'}
               src={`https://api.rajengg.co.in${product.first_image}/`} 
              alt="Card Image" />
              <Box p="6">
                <Box d="flex" alignItems="baseline">
                  <Badge borderRadius="full" px="2" colorScheme="teal">
                    New
                  </Badge>
                  <Text
                    ml="2"
                    textTransform="uppercase"
                    fontSize="sm"
                    fontWeight="bold"
                    color="teal.600"
                  >
                    Product
                  </Text>
                </Box>
                <Box mt="2" fontWeight="semibold" as="h4" lineHeight="tight" isTruncated>
                  {product.name}
                </Box>
                <Text fontSize="xl" fontWeight="bold">
                  &#x20B9; {product.price}
                </Text>
                {/* <Box d="flex" alignItems="center">
                  <Box as="span" color="gray.600" fontSize="sm">
                    4.5
                  </Box>
                  <Box as="span" ml="2" color="gray.600" fontSize="sm">
                    (120)
                  </Box>
                </Box> */}
                <Link to={`/product/${product.slug}/`}>
                  <Button mt="4" colorScheme="teal" size="sm">
                    View Details
                  </Button>
                </Link>
              </Box>
            </Box>
          ))}
        </Flex>
        <Flex align="center" justify="center" mt={4} w="100%">
          {previousPage && (
            <Button onClick={PreviousPageButton} mr={2}>
              Previous
            </Button>
          )}
          <Box className="page-number" mx={2}>
            Page&nbsp;{currentPage}
          </Box>
          {nextPage && (
            <Button onClick={nextPageButton} ml={2}>
              Next
            </Button>
          )}
        </Flex>
      </Flex>
    )
  )
}

export default AllProductDetails;
