import React, { useState, useEffect } from 'react';
import { Box, Container, Heading,   Flex, Text,  Button, Image, } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import LoadingComponent from '../utils/LoadingAnimation';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Link as ScrollLink } from 'react-scroll';
import CompanyProfileCard from './companyProfile';
import ContactForm from './ContactUs';
// import AllProductDetails from './productDetails';
// import { FiEye, FiEyeOff } from 'react-icons/fi'; 
import axiosInstance from '../utils/axiosInstance';
// import AboutUs from './AboutUs';

const MotionBox = motion(Box);


const Hero = () => {
  
  const [isLoading, setIsLoading] = useState(true);
  const [aboutUsImage, setAboutUsImage] = useState([])



 


  useEffect(() => {
    const fetchData = async () => {
      try {
          axiosInstance('list/images/about-us/')
            .then((response) => {
              setAboutUsImage(response.data);
              setIsLoading(false);
            })
        

      } catch (error) {
        console.error(error);
        setIsLoading(false)
      }
    }

    fetchData();
  }, []);

  if (isLoading) {
    return <LoadingComponent />;
  }




  

  const groupedImages = [];
  try {
  
    for (let i = 0; i < aboutUsImage.length; i += 3) {
      groupedImages.push(aboutUsImage.slice(i, i + 3));
    }
  } catch (error) {
    console.error('Error occurred while grouping images:', error);
  }

  return (
    <Box bg="blue.50" minH="100vh" display="flex" flexDir="column">
      <Box
        
        color="black"
        py={16}
        textAlign="center"
      >
      <Container maxW="xl">
  <Box>
    <Features />
  </Box>

   <Heading color={'blackAlpha.600'} as="h1" fontSize={{ base: '2xl', md: '4xl' }} mb={4}>
 Raj Engineering 
  </Heading>

  

  <Text fontSize={{ base: 'md', md: 'xl' }} mb={8}>
    Providing Quality Power Tools for Professionals
  </Text>

  <Button colorScheme="blue" size={{ base: 'md', md: 'lg' }}>
    <Link  to={'/product/'}>Explore Tools</Link>
  </Button>
</Container>
      </Box>

      <Carousel
    showArrows={false}
    showStatus={false}
    showThumbs={false}
    infiniteLoop={true}
    emulateTouch={true}
    autoPlay={true}
    interval={3000}
    stopOnHover={true}
    transitionTime={500}
  >
    {groupedImages.map((images, index) => (
      <Box key={index}>
        <Box display="flex">
          {images.map((imageObj, idx) => (
            <Box key={idx} flex="1" mr={2}>
              <MotionBox
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Image
                  src={imageObj.image}
                  alt={`About Us ${index + 1} - Image ${idx + 1}`}
                  boxSize='100%'
                  objectFit="cover"
                />
              </MotionBox>
            </Box>
          ))}
        </Box>
      </Box>
    ))}
  </Carousel>

      
  <Box >
    <TeamSection />
      <ContactUsSection/>
  </Box>

  <Box>
  <ProductSection/>
  </Box>

  <Box>
  <AboutUsSection/>
  </Box>
     
    </Box>
  );
};

const Features = () => {
  const features = [
    {
      title: 'Wide Selection',
      description: 'Explore our diverse range of tools for versatile engineering applications.',
      icon: 'images/icon1.jpg',
      to:'product-page',
      button:'View Products',
    },
    {
      title: 'High Quality',
      description: 'Our tools are made from durable materials to ensure longevity.',
      icon: 'images/icon2.jpg',
      to:'about-us',
      button:'About-Us',
    },
   
    {
      title: 'Meet Our Team',
      description: 'Get expert advice and support from our knowledgeable team.',
      icon: 'companylogo.png',
      to:'meet-our-team',
      button:'View Our Team'
    },
    {
      title: 'Contact Us',
      description: "Connect with our experts support for all your hardware tool needs. We're here to assist you.",
      icon: 'images/icon3.jpg',
      to:'contact-us',
      button:'Contact-Us'
     
    
    },
  ];

  return (
    <MotionBox
    py={{ base: 8, md: 12, lg: 16 }} // Adjust padding for different screen sizes
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.8 }}
   
  >
    <Container maxW={{ base: "sm", md: "md", lg: "6xl" }} textAlign="center">
  <Heading color={'gray.500'} as="h3" fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }} mb={8}>
    Why Choose Us?
  </Heading>
  <Flex flexWrap="wrap" justifyContent="center" alignItems={'center'}>
    {features.map((feature, index) => (
      <MotionBox
        key={index}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        p={4}
        mx={4}
        mb={8}
        bg="white"
        rounded="lg"
        color="black"
        shadow="md"
        maxW="200px"
        textAlign="center"
      >
        <MotionBox whileHover={{ opacity: 0.8 }}>
          <Image src={feature.icon} alt={feature.title} boxSize="64px" borderRadius="4px" mb={4} mx="auto" />
        </MotionBox>
        <Heading as="h4" fontSize={{ base: "md", md: "2xl" }} mb={2}>
          {feature.title}
        </Heading>
        <Text>{feature.description}</Text>
        <ScrollLink to={feature.to} smooth={true} duration={1000}>
         {feature.to? (<Button>
            {feature.button}
          </Button>):('')}
        </ScrollLink>
      </MotionBox>
    ))}
  </Flex>
</Container>


  </MotionBox>
  );
};

const TeamSection = () => {
 
  
  return (

    <MotionBox
      id="meet-our-team"
      
      py={16}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      
    >
   
    
     
        <Box
          p='40px'
          color='gray'
          mt='4'
          bg='whiteAlpha.200'
          rounded='md'
          shadow='md'
        >
          <Container maxW="6xl" textAlign="center">
        <Heading color={'blackAlpha.600'} as="h3" fontSize="3xl" mb={8}>
          Meet Our Team
        </Heading>
        <CompanyProfileCard/>
      </Container>
        </Box>
    
   
      
      
    </MotionBox>
  );
};


const AboutUsSection = () => {
  return (
    <MotionBox
      id="about-us"
      py={16}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      
    >
      <Container maxW="6xl" textAlign="center">
        <Heading color={'blackAlpha.600'} as="h3" fontSize="3xl" mb={8}>
         About-Us
        </Heading>
      
       <Box mx={'auto'}  maxW={'lg'}>
        <Image 
        src={process.env.PUBLIC_URL+'/images/homepages/about.jpeg'}
        alt='About-us'
        borderRadius={'10px'}
        />
        <Text mt={4} textAlign={'justify'}>
        Since 2013 Raj Engineering is into various field like turnkey projects for civil & mechanical works,
         manufacturing unit of storage racks system, cable tray, & sheet metal works, also deals in Hydraulic Systems,
          Distributor of INGCO power tools, Hand tools & Stanley safety products. Since 2013, 
          Raj Engineering “RE” has grown with Interest of client comes first and our team strives to maximize benefits to our clients. 
        </Text>
       </Box>

      
      </Container>
    </MotionBox>
  );
};

const ContactUsSection = () => {
  return (
    <MotionBox
      id="contact-us"
      py={16}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
    >
      <Container maxW="6xl" textAlign="center">
        <Heading color={'blackAlpha.600'} as="h3" fontSize="3xl" mb={8}>
        Connect with Our Expert Team
        </Heading>
        <ContactForm/>
      </Container>
    </MotionBox>
  );
};

// You'll need to install react-icons and import the icons

const ProductSection = () => {
  // const [isOpen, setIsOpen] = React.useState(true);

  // const handleToggle = () => setIsOpen(!isOpen);

  return (
    <MotionBox
      id="product-page"
      py={16}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
    >
  <Container color={'black'} maxW="6xl" textAlign="center">
  <Heading color={'blackAlpha.600'} as="h3" fontSize="3xl" mb={8}>
    Industrial Innovations: Tools, Hydraulics, Sheds, Storage, and More!
  </Heading>
  <Box p={8} textAlign={'justify'}>
    <Text fontSize="xl" mb={8}>
      Welcome to our comprehensive industrial solutions platform, where quality meets performance. Explore our diverse product range, featuring top-tier offerings in Power Tools, Hydraulic Products, Fabrication Industrial Sheds, Manufacturing Storage Systems, and Awnings and Hangers. Our selection is designed to meet your business needs with precision and reliability. Find the perfect tools and equipment to enhance productivity and efficiency. Trust in our commitment to excellence in every product we offer. Elevate your operations with our trusted industrial solutions. Browse now and experience a new level of performance and quality for your business.
    </Text>
    <Text fontSize="xl" mb={4} fontWeight="bold">
      What We Offer:
    </Text>
    <ul style={{ listStyleType: 'none', padding: 0 }}>
      <li>1. Power Tools</li>
      <li>2. Hydraulic Products</li>
      <li>3. Fabrication Industrial Sheds</li>
      <li>4. Manufacturing Storage Systems</li>
      <li>5. Awnings and Hangers</li>
    </ul>
    <Button  colorScheme="teal" size="md" as={Link} to="/product/">
      View Our Products
    </Button>
  </Box>
</Container>
    </MotionBox>
  );
};



const Homepage = () => {
  return (
    <Box minH="100vh" display="flex" flexDir="column">
      <Hero />
    </Box>
  );
};

export default Homepage;
