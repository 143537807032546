import { Box,   Image, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import axiosInstance from '../utils/axiosInstance';
import LoadingComponent from '../utils/LoadingAnimation';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const AboutUs = () => {
  const [imagesAboutUs, setImageAboutUs] = useState([]);

  const [isLoading, setIsLoading] = useState(true)

  
  const company ={ ...imagesAboutUs[0] };

  useEffect(() => {



    const fetchData = async () => {
    try {
    
     const dataImages =  await axiosInstance.get('list/images/about-us/').then(response=> response.data)
      setImageAboutUs(dataImages)
      if(dataImages){
        setIsLoading(false)
      }
    
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }
    fetchData();

  }, []);
 
  // const company =[{...imagesAboutUs[0]}] ;






 
  if(isLoading){
    return <LoadingComponent />;
  }

  return (
    <Box p={4} textAlign="center">
      <Image
      m={'0 auto'}
      src='/companylogo.png'
      alt=''
      boxSize="40px"
      objectFit="contain"
    />
 
     
      
      <Carousel
  showArrows={true}
  showStatus={false}
  showThumbs={false}
  infiniteLoop={true}
  emulateTouch={true}
  autoPlay={true}
  interval={5000} // Set the time interval for auto-play
  stopOnHover={true}
  transitionTime={500} // Set the transition time in milliseconds
>
  {imagesAboutUs.map((image, index) => (
    <Box
      key={index}
      mx="auto" // Center the box horizontally
      maxWidth={'500px'} // Adjust max width based on screen size
      p={4}
    >
      
      <Image
        src={image.image}
        alt={`About Us ${index + 1}`}
        boxSize="100%"
        objectFit="cover"
        borderRadius="lg"
        boxShadow="lg"
        transition="transform 0.2s ease"
        _hover={{ transform: 'scale(1.05)' }}
      />
    </Box>
  ))}
</Carousel>
   
   <Box whiteSpace={'normal'}  m={'0 auto'} boxSize={'80%'}>
    <Text
  fontSize={{ base: "sm", md: "lg", lg: "xl" }}
>
  {company.author.name}
</Text>
<Text
  fontSize={{ base: "md", md: "lg", lg: "xl" }}
 textAlign={'justify'}
>
  {company.author.description}
</Text>
      
   </Box>

     
    </Box>
  );
};

export default AboutUs;
