import React,{useEffect, useState} from 'react';
import { Box, Flex, Heading,Image, Text } from '@chakra-ui/react';

import axiosInstance from '../utils/axiosInstance';


const CompanyProfileCard = () => {
    const [profileData, setProfileData] = useState([]);
    

    useEffect(() => {

      try {
        axiosInstance.get('company/profile/',)
          .then(response => setProfileData(response.data)).catch(error => {
            console.error('Error fetching profile data:', error);
          });
      } catch (error) {
        console.log(`error while fetching company profie ${error}`)
      }
      
       
      }, []);


  return (
  
  <>
  <Flex flexDirection="row" wrap={'wrap'} alignItems="center">
  {profileData.map((profile) => (
    <Box
      key={profile.id}
      p={4}
      mx={4}
      mt={4}
      maxW="300px"
      h="max-content"
      borderWidth="1px"
      borderRadius="0 40px 0 40px"
      overflow="hidden"
      bg="white"
      boxShadow="md"
      mb={4}
    >
      <Box p={4} textAlign="center">
        <Image
          src={profile.profile_picture}
          alt="Team Member 1"
          boxSize="64px"
          borderRadius="full"
          mx="auto"
        />
        <Heading as="h4" fontSize="xl" my={2}>
          {profile.designation}
        </Heading>
        <Text>{profile.name}</Text>
        <Text mt={2} fontSize="sm">
          {profile.Bio}
        </Text>
      </Box>
    </Box>
  ))}
</Flex>

  </>
   
  );
};

export default CompanyProfileCard;
